import { Link } from "@reach/router";
import React, { useCallback, useState } from "react";
import fetchWrapper from "./fetchWrapper";
import useTitle from "./useTitle";
import "./ResetPassword.css";

const ResetPassword = ({ token }) => {
  const [passwordNew1, setPasswordNew1] = useState("");
  const [passwordNew2, setPasswordNew2] = useState("");
  const [passwordError, setPasswordError] = useState();
  const [submittingPassword, setSubmittingPassword] = useState(false);
  const [done, setDone] = useState(false);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      setPasswordError();
      setSubmittingPassword(true);

      const result = await fetchWrapper("reset_password", {
        method: "POST",
        data: {
          passwordNew1,
          passwordNew2,
          token
        }
      });

      setSubmittingPassword(false);
      if (result.success) {
        setDone(true);
      } else if (result.errorMessage) {
        setPasswordError(result.errorMessage);
      } else {
        setPasswordError("Unknown error");
      }
    },
    [passwordNew1, passwordNew2, token]
  );

  useTitle("Reset Password");

  return (
    <div className="centered-wrapper">
      <div className="reset-password-container">
        <h1>Reset Password</h1>
        {done ? (
          <p>
            Thank you! You can now <Link to="/">log in</Link> with your new
            password.
          </p>
        ) : (
          <>
            <p>Enter your new password twice below.</p>
            <form onSubmit={handleSubmit}>
              <input
                type="password"
                className="form-control reset-password-first"
                placeholder="New password"
                required
                value={passwordNew1}
                onChange={event => {
                  setPasswordNew1(event.target.value);
                }}
              />
              <input
                type="password"
                className="form-control reset-password-second"
                placeholder="Re-type new password"
                required
                value={passwordNew2}
                onChange={event => {
                  setPasswordNew2(event.target.value);
                }}
              />
              <button
                type="submit"
                className="btn btn-lg btn-primary btn-block"
                disabled={submittingPassword}
              >
                Reset Password
              </button>
            </form>
            {passwordError ? (
              <p className="text-danger mt-3">{passwordError}</p>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
