import { Link } from "@reach/router";
import groupBy from "lodash/groupBy";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryScatter,
  VictoryTooltip,
  VictoryLine
} from "victory";
import MyDateRangePicker from "./MyDateRangePicker";
import * as format from "./format";
import fetchWrapper from "./fetchWrapper";
import useUser from "./useUser";
import useTitle from "./useTitle";
import useWidth from "./useWidth";
import "./Dashboard.css";

const formatDate = x => {
  const [, month, day] = x.split("-");
  const mon = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ][parseInt(month, 10) - 1];

  if (day !== undefined) {
    // By day
    return `${mon} ${parseInt(day, 10)}`;
  }

  if (mon !== undefined) {
    // By month
    return mon;
  }

  // By week
  return month;
};

const tickFormat = {
  currency: x => format.currency(x, true),
  integer: x => format.integer(x, true)
};

const chartPadding = { top: 10, left: 50, right: 25, bottom: 35 };

const styleAxis = {
  axis: { stroke: "#eee" },
  grid: { stroke: "#eee", strokeDasharray: "10, 5" },
  tickLabels: { fill: "#fff", fontSize: 15 }
};
const styleTooltip = { fontSize: 15 };

const Plot = ({ lines }) => {
  const ref = useRef(null);
  const width = useWidth(ref);

  const allYValues = [];
  for (const line of lines) {
    allYValues.push(...line.records.map(record => record[line.field]));
  }

  return (
    <div ref={ref}>
      <VictoryChart padding={chartPadding} height={175} width={width}>
        <VictoryAxis
          fixLabelOverlap
          style={styleAxis}
          tickFormat={formatDate}
        />
        <VictoryAxis
          dependentAxis
          domain={[0, 1.1 * Math.max(...allYValues)]}
          style={styleAxis}
          tickFormat={tickFormat[lines[0].type]}
        />

        {lines
          .map(({ color, field, records, recordsPrevious = [], type }) => {
            const styleLine = {
              data: {
                stroke: color,
                strokeWidth: 3
              }
            };
            const styleLineDashed = {
              data: {
                stroke: color,
                strokeOpacity: 0.75,
                strokeWidth: 2,
                strokeDasharray: "10,10"
              }
            };
            const styleScatter = { data: { fill: color } };
            const styleScatterDashed = {
              data: { fill: color, fillOpacity: 0.75 }
            };

            const labels = ({ datum }) => {
              return `${datum.date}: ${format[type](datum[field])}`;
            };

            let size;
            if (records.length <= 20) {
              size = 7;
            } else if (records.length <= 25) {
              size = 6;
            } else if (records.length <= 30) {
              size = 5;
            } else if (records.length <= 40) {
              size = 4;
            } else {
              size = 3;
            }

            const recordsPreviousFudgedDate = recordsPrevious.map(row => {
              const parts = row.date.split("-");
              const newYear = parseInt(parts[0]) + 1;
              return {
                ...row,
                fakeDate: `${newYear}-${parts.slice(1).join("-")}`
              };
            });

            return [
              <VictoryGroup key={field} data={records} x="date" y={field}>
                <VictoryLine interpolation="cardinal" style={styleLine} />
                <VictoryScatter
                  labels={labels}
                  labelComponent={<VictoryTooltip style={styleTooltip} />}
                  size={size}
                  style={styleScatter}
                />
              </VictoryGroup>,
              recordsPrevious.length > 0 ? (
                <VictoryGroup
                  key={`${field}-previous`}
                  data={recordsPreviousFudgedDate}
                  x="fakeDate"
                  y={field}
                >
                  <VictoryLine
                    interpolation="cardinal"
                    style={styleLineDashed}
                  />
                  <VictoryScatter
                    labels={labels}
                    labelComponent={<VictoryTooltip style={styleTooltip} />}
                    size={size * 0.75}
                    style={styleScatterDashed}
                  />
                </VictoryGroup>
              ) : null
            ];
          })
          .flat()}
      </VictoryChart>
    </div>
  );
};

const Site = React.memo(({ records, recordsPrevious, site }) => {
  if (records.length === 0) {
    return null;
  }

  const revenueLines = [
    {
      color: "var(--red)",
      field: "revenue",
      records,
      recordsPrevious,
      type: "currency"
    }
  ];

  return (
    <div className="mb-5">
      <h1>{site}</h1>
      <div className="row mt-3 mt-lg-1">
        <div className="col-lg mb-3 mb-lg-0">
          <h2 className="mb-1" style={{ color: "var(--red)" }}>
            Revenue (USD)
          </h2>
          <Plot lines={revenueLines} />
        </div>
        <div className="col-lg mb-3 mb-lg-0">
          <h2 className="mb-1">
            <span style={{ color: "var(--teal)" }}>Impressions,</span>{" "}
            <span style={{ color: "var(--yellow)" }}>Pageviews</span>
          </h2>
          <Plot
            lines={[
              {
                color: "var(--teal)",
                field: "impressions",
                records,
                recordsPrevious,
                type: "integer"
              },
              {
                color: "var(--yellow)",
                field: "pageviews",
                records,
                recordsPrevious,
                type: "integer"
              }
            ]}
          />
        </div>
        <div className="col-lg">
          <h2 className="mb-1">
            <span style={{ color: "var(--pink)" }}>CPM (USD),</span>{" "}
            <span style={{ color: "var(--cyan)" }}>pvRPM (USD)</span>
          </h2>
          <Plot
            lines={[
              {
                color: "var(--pink)",
                field: "cpm",
                records,
                recordsPrevious,
                type: "currency"
              },
              {
                color: "var(--cyan)",
                field: "rpm",
                records,
                recordsPrevious,
                type: "currency"
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
});

const makeBlankRow = (site, today) => {
  const row = {
    cpm: 0,
    date: today,
    impressions: 0,
    ipv: 0,
    pageviews: 0,
    revenue: 0,
    rpm: 0,
    site
  };

  return row;
};

const Dashboard = () => {
  useTitle("Dashboard");

  const params = new URLSearchParams(window.location.hash.slice(1));

  const user = useUser();
  const [endDate, setEndDate] = useState(
    params.get("endDate")
      ? moment(params.get("endDate"))
      : moment(new Date()).subtract(1, "day")
  );
  const [startDate, setStartDate] = useState(
    params.get("startDate")
      ? moment(params.get("startDate"))
      : endDate
      ? endDate.clone().subtract(2, "weeks")
      : new Date()
  );
  const [timeGrouping, setTimeGrouping] = useState(
    params.get("timeGrouping") || "day"
  );
  const [userOrTotal, setUserOrTotal] = useState(
    user.role === "admin" ? params.get("userOrTotal") || "total" : "user"
  );
  const [sites, setSites] = useState([]);
  const [records, setRecords] = useState([]);
  const [recordsPrevious, setRecordsPrevious] = useState([]);
  const [running, setRunning] = useState(true);
  const [recordsLastUpdated, setRecordsLastUpdated] = useState("???");

  const fetchData = async () => {
    setRunning(true);

    const actuallyFetchData = async lastYear => {
      const query = {
        startDate: startDate
          .clone()
          .subtract(lastYear ? 1 : 0, "years")
          .format("YYYY-MM-DD"),
        endDate: endDate
          .clone()
          .subtract(lastYear ? 1 : 0, "years")
          .format("YYYY-MM-DD"),
        timeGrouping,
        userOrTotal,
        date: true,
        site: true,
        adUnit: false,
        bidder: false,
        device: false
      };

      const result = await fetchWrapper("get_records", {
        method: "POST",
        data: query
      });

      return result;
    };

    const result = await actuallyFetchData(false);

    if (result.recordsLastUpdated) {
      const date = moment.utc(result.recordsLastUpdated);
      setRecordsLastUpdated(date.local().format("YYYY-MM-DD h:mm a"));
    }

    const allSites = Array.from(
      new Set(result.records.map(record => record.site))
    ).sort();

    // Prevent from triggering re-render of plots
    if (JSON.stringify(allSites) !== JSON.stringify(sites)) {
      setSites(allSites);
    }

    const resultLastYear = await actuallyFetchData(true);

    setRecords(result.records.reverse());
    setRecordsPrevious(resultLastYear.records.reverse());

    setRunning(false);
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recordsByDate = useMemo(() => {
    return {
      current: groupBy(records, "date"),
      previous: groupBy(recordsPrevious, "date")
    };
  }, [records, recordsPrevious]);

  // Special case - add latest date, if it's requested but not there, to facilitate visual comparisons after adding a day's data
  const today = moment(new Date())
    .subtract(1, "day")
    .format("YYYY-MM-DD");
  const addDummyRecord =
    timeGrouping === "day" &&
    endDate &&
    endDate.format("YYYY-MM-DD") === today &&
    !recordsByDate.current[today];

  const recordsBySite = useMemo(() => {
    return sites.map(site => {
      const current = records.filter(record => record.site === site);

      if (addDummyRecord && current.length > 0) {
        current.push(makeBlankRow(site, today));
      }

      const previous = recordsPrevious.filter(record => record.site === site);

      return {
        current,
        previous
      };
    });

    // Purposely ignore "addDummyRecord" and "today", because that is just form state until it's actually submitted and then records will be updated. I guess it should not be used here, because then the wrong thing will happen if the form changes quickly, but whatever.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records, recordsPrevious, sites]);

  const recordsTotal = useMemo(() => {
    if (sites.length <= 1) {
      return undefined;
    }

    const site = "Total";

    const getTotal = byDate => {
      const recordsTotal = [];
      for (const [date, recordsAtDate] of Object.entries(byDate)) {
        let impressions = 0;
        let pageviews = 0;
        let revenue = 0;
        for (const record of recordsAtDate) {
          impressions += record.impressions;
          pageviews += record.pageviews;
          revenue += record.revenue;
        }

        const recordTotal = {
          cpm: impressions > 0 ? (revenue / impressions) * 1000 : 0,
          date,
          impressions,
          ipv: pageviews > 0 ? impressions / pageviews : 0,
          pageviews,
          revenue,
          rpm: pageviews > 0 ? (revenue / pageviews) * 1000 : 0,
          site
        };

        recordsTotal.push(recordTotal);
      }

      return recordsTotal;
    };

    const current = getTotal(recordsByDate.current);
    const previous = getTotal(recordsByDate.previous);

    if (addDummyRecord && current.length > 0) {
      current.push(makeBlankRow(site, today));
    }

    return {
      current,
      previous
    };

    // Purposely ignore "addDummyRecord" and "today", because that is just form state until it's actually submitted and then records will be updated. I guess it should not be used here, because then the wrong thing will happen if the form changes quickly, but whatever.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsByDate, sites]);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <form
            className="form-inline mb-3"
            onSubmit={async event => {
              event.preventDefault();

              const formData = {
                startDate: startDate.format("YYYY-MM-DD"),
                endDate: endDate.format("YYYY-MM-DD"),
                timeGrouping,
                userOrTotal
              };
              const params = new URLSearchParams(formData).toString();
              window.history.replaceState(null, null, `#${params}`);

              await fetchData();
            }}
          >
            <MyDateRangePicker
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <select
              className="form-control mt-2 mt-sm-0 ml-sm-2"
              value={timeGrouping}
              onChange={event => {
                setTimeGrouping(event.target.value);
              }}
            >
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
            </select>
            {user.role === "admin" ? (
              <select
                className="form-control mt-2 mt-sm-0 ml-sm-2"
                value={userOrTotal}
                onChange={event => {
                  setUserOrTotal(event.target.value);
                }}
              >
                <option value="user">User Cut</option>
                <option value="publisher">Publisher Cut</option>
                <option value="total">Total</option>
              </select>
            ) : null}
            <button
              className="btn btn-primary mt-2 mt-sm-0 ml-sm-2"
              disabled={running}
              type="submit"
            >
              {running ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Loading...
                </>
              ) : (
                "Update"
              )}
            </button>
          </form>
        </div>
        <div className="col-md-6 text-md-right mb-3 mb-md-0">
          Warning: This data will not exactly match what you are paid. These are
          just estimates.
          <br />
          Data last updated: {recordsLastUpdated}
        </div>
      </div>
      {recordsTotal !== undefined ? (
        <Site
          site="Total"
          records={recordsTotal.current}
          recordsPrevious={recordsTotal.previous}
        />
      ) : null}
      {sites.map((site, i) => (
        <Site
          key={site}
          site={site}
          records={recordsBySite[i].current}
          recordsPrevious={recordsBySite[i].previous}
        />
      ))}
      {sites.length > 0 ? (
        <p>
          View detailed stats on <Link to="/reporting">the reporting page</Link>
          .
        </p>
      ) : null}
    </>
  );
};

export default Dashboard;
