import create from "zustand";

const [useUser] = create(set => ({
  email: undefined,
  role: undefined,

  actions: {
    login: ({ email, role }) => set(() => ({ email, role })),
    logout: () => set(() => ({ email: undefined, role: undefined }))
  }
}));

export default useUser;
