import React, { useCallback, useEffect, useState } from "react";
import Protected from "./Protected";
import fetchWrapper from "./fetchWrapper";
import useTitle from "./useTitle";

const Row = ({ site, onUpdate }) => {
  const [editing, setEditing] = useState(false);
  const [controlledName, setControlledName] = useState(site.name);
  const [controlledShortName, setControlledShortName] = useState(
    site.short_name
  );
  const [
    controlledGoogleAnalyticsViewID,
    setControlledGoogleAnalyticsViewID
  ] = useState(site.google_analytics_view_id);
  const [controlledFreestarID, setControlledFreestarID] = useState(
    site.freestar_id
  );
  const [errorMessage, setErrorMessage] = useState();

  const saveSite = useCallback(async () => {
    setErrorMessage();

    const result = await fetchWrapper("update_site", {
      method: "POST",
      data: {
        id: site.id,
        name: controlledName,
        shortName: controlledShortName,
        googleAnalyticsViewID: controlledGoogleAnalyticsViewID,
        freestarID: controlledFreestarID
      }
    });

    if (result.success) {
      await onUpdate();
      setEditing(false);
    } else if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    } else {
      setErrorMessage("Unknown error");
    }
  }, [
    controlledName,
    controlledShortName,
    controlledGoogleAnalyticsViewID,
    controlledFreestarID,
    onUpdate,
    site.id
  ]);

  const deleteSite = useCallback(async () => {
    setErrorMessage();

    const result = await fetchWrapper("delete_site", {
      method: "POST",
      data: {
        id: site.id
      }
    });

    if (result.success) {
      await onUpdate();
    } else if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    } else {
      setErrorMessage("Unknown error");
    }
  }, [onUpdate, site.id]);

  return (
    <tr>
      <td>{site.id}</td>
      <td>
        {!editing ? (
          site.name
        ) : (
          <input
            className="form-control-sm"
            type="text"
            required
            value={controlledName}
            onChange={event => {
              setControlledName(event.target.value);
            }}
          />
        )}
      </td>
      <td>
        {!editing ? (
          site.short_name
        ) : (
          <input
            className="form-control-sm"
            type="text"
            required
            value={controlledShortName}
            onChange={event => {
              setControlledShortName(event.target.value);
            }}
          />
        )}
      </td>
      <td>
        {!editing ? (
          site.google_analytics_view_id
        ) : (
          <input
            className="form-control-sm"
            type="text"
            required
            value={controlledGoogleAnalyticsViewID}
            onChange={event => {
              setControlledGoogleAnalyticsViewID(event.target.value);
            }}
          />
        )}
      </td>
      <td>
        {!editing ? (
          site.freestar_id
        ) : (
          <input
            className="form-control-sm"
            type="text"
            required
            value={controlledFreestarID}
            onChange={event => {
              setControlledFreestarID(event.target.value);
            }}
          />
        )}
      </td>
      <td>
        {editing ? (
          <div className="btn-group">
            <button className="btn btn-primary btn-sm" onClick={saveSite}>
              Save
            </button>
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => {
                setErrorMessage();
                setEditing(false);
                setControlledName(site.name);
                setControlledShortName(site.short_name);
                setControlledGoogleAnalyticsViewID(
                  site.google_analytics_view_id
                );
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="btn-group">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setErrorMessage();
                setEditing(true);
              }}
            >
              Edit
            </button>
            <button className="btn btn-secondary btn-sm" onClick={deleteSite}>
              Delete
            </button>
          </div>
        )}
        {errorMessage ? (
          <p className="text-danger mt-2 mb-0">{errorMessage}</p>
        ) : null}
      </td>
    </tr>
  );
};

const AddSite = ({ onUpdate }) => {
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [googleAnalyticsViewID, setGoogleAnalyticsViewID] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      setErrorMessage();

      const result = await fetchWrapper("create_site", {
        method: "POST",
        data: {
          name,
          shortName,
          googleAnalyticsViewID
        }
      });

      if (result.success) {
        await onUpdate();

        setName("");
        setShortName("");
      } else if (result.errorMessage) {
        setErrorMessage(result.errorMessage);
      } else {
        setErrorMessage("Unknown error");
      }
    },
    [name, onUpdate, shortName, googleAnalyticsViewID]
  );

  return (
    <>
      <form className="form-inline" onSubmit={handleSubmit}>
        <input
          type="text"
          className="form-control mr-sm-2 mb-2"
          placeholder="Name"
          required
          value={name}
          onChange={event => {
            setName(event.target.value);
          }}
        />
        <input
          type="text"
          className="form-control mr-sm-2 mb-2"
          placeholder="Short Name"
          required
          value={shortName}
          onChange={event => {
            setShortName(event.target.value);
          }}
        />
        <input
          type="text"
          className="form-control mr-sm-2 mb-2"
          placeholder="GA Property ID"
          value={googleAnalyticsViewID}
          onChange={event => {
            setGoogleAnalyticsViewID(event.target.value);
          }}
        />
        <button type="submit" className="btn btn-primary mb-2">
          Add site
        </button>
      </form>
      {errorMessage ? <p className="text-danger">{errorMessage}</p> : null}
    </>
  );
};

const Sites = () => {
  useTitle("Sites");

  const [sites, setSites] = useState();

  const fetchData = async () => {
    const result = await fetchWrapper("admin_data");

    setSites(result.sites);
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (sites === undefined) {
    return null;
  }

  return (
    <>
      <p>
        <span className="text-warning">
          The "Short Name" field is important!
        </span>{" "}
        It must be a string that is in all of the ad unit codes for this site.
        This is how the data loader maps ad units to sites.
      </p>
      <p>
        If you pick a string for "Short Name" that is not unique (like "slam"
        and "slam_newswire" - the latter contains the former) then ad units
        matching both will be counted for both sites.
      </p>
      <p>
        The "Name" field is not important, it's just used in the dashboard UI.
      </p>
      <p>
        The Google Analytics Property ID can be found in the admin panel of GA4
        for your site. Also make sure
        fetch-data@bbgm-ads.iam.gserviceaccount.com has read access to the GA4
        property (in GA4, go to Admin &gt; Account User Management or Property
        User Management).
      </p>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Short Name</th>
              <th>GA4 Property ID</th>
              <th>Freestar ID</th>
              <th style={{ width: 0 }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sites.map(site => (
              <Row key={site.id} site={site} onUpdate={fetchData} />
            ))}
          </tbody>
        </table>
      </div>
      <h1>Add site</h1>
      <AddSite onUpdate={fetchData} />
    </>
  );
};

const ProtectedSites = () => {
  return (
    <Protected admin>
      <Sites />
    </Protected>
  );
};

export default ProtectedSites;
