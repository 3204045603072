import { Link } from "@reach/router";
import React, { useCallback, useState } from "react";
import fetchWrapper from "./fetchWrapper";
import useTitle from "./useTitle";
import useUser from "./useUser";
import "./Login.css";

const Login = () => {
  useTitle("Login");

  const user = useUser();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      setErrorMessage(undefined);

      let response;
      try {
        response = await fetchWrapper("login", {
          method: "POST",
          data: {
            email,
            password
          }
        });
      } catch (err) {
        setErrorMessage(
          "Error connecting to server. Check your Internet connection or try again later."
        );
        return;
      }

      if (response.success && response.email && response.role) {
        user.actions.login({
          email: response.email,
          role: response.role
        });
      } else {
        setErrorMessage("Invalid email or password.");
      }
    },
    [email, password, user]
  );

  return (
    <div className="centered-wrapper">
      <form className="form-login" onSubmit={handleSubmit}>
        <label htmlFor="inputEmail" className="sr-only">
          Email address
        </label>
        <input
          type="email"
          id="inputEmail"
          className="form-control"
          placeholder="Email address"
          required
          autoFocus
          value={email}
          onChange={event => {
            setEmail(event.target.value);
          }}
        />
        <label htmlFor="inputPassword" className="sr-only">
          Password
        </label>
        <input
          type="password"
          id="inputPassword"
          className="form-control"
          placeholder="Password"
          required
          value={password}
          onChange={event => {
            setPassword(event.target.value);
          }}
        />
        <button className="btn btn-lg btn-primary btn-block mb-4" type="submit">
          Log In
        </button>
        <div className="text-danger mb-3">
          {errorMessage ? errorMessage : "\u00A0"}
        </div>
        <Link to="/reset-password">Forgot password?</Link>
      </form>
    </div>
  );
};

export default Login;
