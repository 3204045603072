const fetchWrapper = async (api, { method, data } = {}) => {
  let body;
  if (
    (typeof FormData !== "undefined" && data instanceof FormData) ||
    (typeof URLSearchParams !== "undefined" && data instanceof URLSearchParams)
  ) {
    body = data;
  } else if (data !== undefined) {
    body = new URLSearchParams();
    for (const key of Object.keys(data)) {
      body.set(key, data[key]);
    }
  }

  const response = await fetch(`/api/${api}.php`, {
    body,
    method
  });
  const result = await response.json();

  if (process.env.NODE_ENV === "development") {
    console.log(api, result);
  }

  return result;
};

export default fetchWrapper;
