import React, { useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "./MyDateRangePicker.css";

const MyDateRangePicker = ({
  endDate,
  setEndDate,
  setStartDate,
  startDate
}) => {
  const [datePickerFocusedInput, setDatePickerFocusedInput] = useState(null);

  return (
    <DateRangePicker
      small
      startDate={startDate}
      startDateId="your_unique_start_date_id"
      endDate={endDate}
      endDateId="your_unique_end_date_id"
      onDatesChange={({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
      }}
      focusedInput={datePickerFocusedInput}
      onFocusChange={setDatePickerFocusedInput}
      isOutsideRange={() => false}
      minimumNights={0}
    />
  );
};

export default MyDateRangePicker;
