import { Link } from "@reach/router";
import { csvParse } from "d3-dsv";
import groupBy from "lodash/groupBy";
import React, { useEffect, useState } from "react";
import Protected from "./Protected";
import fetchWrapper from "./fetchWrapper";
import useTitle from "./useTitle";

const Freestar = () => {
  useTitle("Freestar Data Import");

  const [sites, setSites] = useState();
  const [status, setStatus] = useState({
    type: "init"
  });
  const [errorMessage, setErrorMessage] = useState();

  const fetchData = async () => {
    const result = await fetchWrapper("admin_data");

    setSites(result.sites);
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (sites === undefined) {
    return null;
  }

  const siteIDByFreestarID = {};
  for (const site of sites) {
    if (site.freestar_id !== "") {
      const freestarIDs = site.freestar_id.split("|||");
      for (const freestarID of freestarIDs) {
        siteIDByFreestarID[freestarID] = site.id;
      }
    }
  }

  const processCSV = async text => {
    // Remove first line of crap
    let csv = text;
    csv = csv.substring(csv.indexOf("\n") + 1);

    // Code below assumes old Freestar column names
    const renameCols = {
      "Site Name": "site_name",
      Date: "date",
      "Ad Unit": "ad_unit",
      Network: "network_name",
      "Device Type": "device_type",
      Impressions: "impressions",
      "Net Revenue": "revenue"
    };

    const rows = csvParse(csv).map(row => {
      const newRow = {};
      for (const [oldCol, newCol] of Object.entries(renameCols)) {
        if (typeof row[oldCol] !== "string") {
          console.log(row);
          throw new Error(`Invalid or missing column ${oldCol}`);
        }
        newRow[newCol] = row[oldCol];
      }
      return newRow;
    });

    if (rows.length === 0) {
      throw new Error("No rows found in file");
    }

    /**
     * site_id INT UNSIGNED NOT NULL,
     * record_date DATE NOT NULL,
     * ad_unit VARCHAR(255) NOT NULL,
     * bidder VARCHAR(255) NOT NULL,
     * device VARCHAR(255) NOT NULL,
     * impressions INT UNSIGNED NOT NULL,
     * revenue INT UNSIGNED NOT NULL,
     */
    return rows.map(row => {
      const siteID = siteIDByFreestarID[row.site_name];
      if (!siteID) {
        console.log(row);
        throw new Error(
          `Invalid Freestar ID ${
            row.site_name
          } - see the Sites tab, is it missing?`
        );
      }

      const impressions = parseInt(row.impressions);
      if (Number.isNaN(impressions)) {
        console.log(row);
        throw new Error(`Invalid impressions`);
      }

      const revenue = Math.round(parseFloat(row.revenue) * 1000000);
      if (Number.isNaN(revenue)) {
        console.log(row);
        throw new Error(`Invalid revenue`);
      }

      return [
        siteID,
        row.date,
        `${row.ad_unit} (FS)`,
        `${row.network_name} (FS)`,
        `${row.device_type} (FS)`,
        impressions,
        revenue
      ];
    });
  };

  return (
    <>
      <p>
        Upload data exported from Freestar here. Should include the following
        metrics:
      </p>
      <ul>
        <li>Impressions</li>
        <li>Net Revenue</li>
      </ul>
      <p>And the following dimensions:</p>
      <ul>
        <li>Ad Unit</li>
        <li>Date</li>
        <li>Device Type</li>
        <li>Network</li>
        <li>
          Site Name (values must correspond with entries in the Freestar ID
          column in the <Link to="/sites">Sites tab</Link>)
        </li>
      </ul>
      <p>
        Inventory type needs to be "Web", and type of report needs to be
        "Network".
      </p>
      <p>
        There is not necessarily a one-to-one correspondance between the
        dimension values in Freestar and in our old system, except for Date and
        Site, so the others will have (FS) appended to them after import.
      </p>
      <input
        type="file"
        className="form-control-file"
        onChange={event => {
          setStatus({
            type: "loading"
          });
          const file = event.currentTarget.files[0];
          if (!file) {
            setStatus({
              type: "init"
            });
            return;
          }

          const reader = new window.FileReader();
          reader.readAsText(file);

          reader.onload = async event2 => {
            try {
              const text = event2.currentTarget.result;
              const rowsAll = await processCSV(text);

              const rowsByDate = groupBy(rowsAll, row => row[1]);

              let current = 1;
              const total = Object.keys(rowsByDate).length;
              for (const [date, rows] of Object.entries(rowsByDate)) {
                setStatus({
                  type: "loading",
                  current,
                  total
                });
                const response = await fetchWrapper("upsert_freestar", {
                  method: "POST",
                  data: {
                    rows: JSON.stringify(rows),
                    date: date
                  }
                });
                if (!response.success) {
                  throw new Error(response.errorMessage);
                }
                current += 1;
              }

              setStatus({
                type: "done"
              });
            } catch (error) {
              setStatus({
                type: "error"
              });
              setErrorMessage(error.message);
            }
          };
        }}
        onClick={event => {
          event.target.value = null;
        }}
      />
      {status.type === "loading" ? (
        <p className="mt-3 text-warning">
          Loading...
          {status.current !== undefined && status.total !== undefined
            ? ` (${status.current}/${status.total} days)`
            : null}
        </p>
      ) : null}
      {status.type === "done" ? (
        <p className="mt-3 text-success">Done!</p>
      ) : null}
      {status.type === "error" ? (
        <p className="mt-3 text-danger">Error: {errorMessage}</p>
      ) : null}
    </>
  );
};

const ProtectedFreestar = () => {
  return (
    <Protected admin>
      <Freestar />
    </Protected>
  );
};

export default ProtectedFreestar;
