const formatFactory = (defaultFractionDigits, prefix) => (
  x,
  shorten = false
) => {
  let suffix = "";

  if (shorten) {
    if (x >= 999500000) {
      x /= 1000000000;
      suffix = "B";
    } else if (x >= 999500) {
      x /= 1000000;
      suffix = "M";
    } else if (x >= 999.5) {
      x /= 1000;
      suffix = "k";
    }
  }

  let minimumFractionDigits = defaultFractionDigits;
  let maximumFractionDigits = defaultFractionDigits;
  if (shorten) {
    if (suffix === "") {
      if (x >= 10) {
        minimumFractionDigits = 0;
        maximumFractionDigits = 0;
      } else if (prefix === "$" && defaultFractionDigits === 2) {
        // Since we can't say "use either 0 or 2 digits", switch manually
        const remainder = x % 1;
        if (remainder >= 0.995 || remainder < 0.005) {
          minimumFractionDigits = 0;
          maximumFractionDigits = 0;
        }
      }
    } else {
      if (x >= 10) {
        minimumFractionDigits = 0;
        maximumFractionDigits = 0;
      } else {
        minimumFractionDigits = 0;
        maximumFractionDigits = 1;
      }
    }
  }

  return `${prefix}${x.toLocaleString("en-US", {
    minimumFractionDigits,
    maximumFractionDigits
  })}${suffix}`;
};

export const currency = formatFactory(2, "$");

export const integer = formatFactory(0, "");
