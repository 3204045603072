import React, { useEffect, useState } from "react";
import fetchWrapper from "./fetchWrapper";
import { currency } from "./format";
import useTitle from "./useTitle";
import useUser from "./useUser";

const amountIntToString = amount => {
  const string = String(amount);
  return `${string.slice(0, -2)}.${string.slice(-2)}`;
};

const amountStringToInt = amount => {
  const string = amount
    .replaceAll("-", "")
    .replaceAll("$", "")
    .replaceAll(",", "")
    .replace(".", "");
  return parseInt(string);
};

const nullToEmptyString = x => (x === null ? "" : x);
const emptyStringToNull = x => (x === "" ? null : x);

const Row = ({ admin, multipleUsers, onUpdate, payment }) => {
  const [editing, setEditing] = useState(false);
  const [controlledEarnedMonth, setControlledEarnedMonth] = useState(
    payment.earned_month
  );
  const [controlledPaidDate, setControlledPaidDate] = useState(
    nullToEmptyString(payment.paid_date)
  );
  const [controlledAmount, setControlledAmount] = useState(
    amountIntToString(payment.amount)
  );
  const [errorMessage, setErrorMessage] = useState();
  const [emailSent, setEmailSent] = useState(false);

  const savePayment = async event => {
    event.preventDefault();
    setErrorMessage();

    const result = await fetchWrapper("update_payment", {
      method: "POST",
      data: {
        id: payment.id,
        earned_month: controlledEarnedMonth,
        paid_date: emptyStringToNull(controlledPaidDate),
        amount: amountStringToInt(controlledAmount)
      }
    });

    if (result.success) {
      await onUpdate();
      setEditing(false);
    } else if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    } else {
      setErrorMessage("Unknown error");
    }
  };

  const deletePayment = async () => {
    setErrorMessage();

    const result = await fetchWrapper("delete_payment", {
      method: "POST",
      data: {
        id: payment.id
      }
    });

    if (result.success) {
      await onUpdate();
      setEmailSent(true);
    } else if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    } else {
      setErrorMessage("Unknown error");
    }
  };

  const sendEmail = async (dryRun = true) => {
    setErrorMessage();

    const data = {
      id: payment.id
    };
    if (dryRun) {
      data.dry_run = true;
    }

    const result = await fetchWrapper("email_payment", {
      method: "POST",
      data
    });

    if (result.success) {
      if (dryRun) {
        const proceed = window.confirm(
          `Send this email?\n\nTo: ${result.email}\nSubject: ${
            result.subject
          }\n\n${result.message}`
        );
        if (proceed) {
          sendEmail(false);
        }
      } else {
        setEmailSent(true);
      }
    } else if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    } else {
      setErrorMessage("Unknown error");
    }
  };

  return (
    <tr>
      <td>
        {!editing ? (
          controlledEarnedMonth
        ) : (
          <form onSubmit={savePayment}>
            <input
              className="form-control form-control-sm"
              type="text"
              required
              value={controlledEarnedMonth}
              onChange={event => {
                setControlledEarnedMonth(event.target.value);
              }}
            />
          </form>
        )}
      </td>
      <td>
        {!editing ? (
          controlledPaidDate === "" ? (
            "Pending"
          ) : (
            controlledPaidDate
          )
        ) : (
          <form onSubmit={savePayment}>
            <input
              className="form-control form-control-sm"
              type="text"
              value={controlledPaidDate}
              onChange={event => {
                setControlledPaidDate(event.target.value);
              }}
            />
          </form>
        )}
      </td>
      {multipleUsers ? <td>{payment.name}</td> : null}
      <td>
        {!editing ? (
          currency(parseFloat(controlledAmount))
        ) : (
          <form onSubmit={savePayment}>
            <div className="input-group input-group-sm">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                className="form-control form-control-sm"
                type="text"
                pattern="\d+\.\d\d"
                required
                value={controlledAmount}
                onChange={event => {
                  setControlledAmount(event.target.value);
                }}
              />
            </div>
          </form>
        )}
      </td>
      {admin ? (
        <td>
          {editing ? (
            <div className="btn-group">
              <button className="btn btn-primary btn-sm" onClick={savePayment}>
                Save
              </button>
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  setErrorMessage();
                  setEditing(false);
                  setControlledEarnedMonth(payment.earned_month);
                  setControlledPaidDate(nullToEmptyString(payment.paid_date));
                  setControlledAmount(amountIntToString(payment.amount));
                }}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div className="btn-group">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setErrorMessage();
                  setEditing(true);
                }}
              >
                Edit
              </button>
              <button
                className="btn btn-secondary btn-sm"
                onClick={deletePayment}
              >
                Delete
              </button>
              <button
                className="btn btn-warning btn-sm text-light"
                onClick={() => {
                  sendEmail(true);
                }}
                disabled={emailSent}
              >
                {emailSent ? "Sent!" : "Email"}
              </button>
            </div>
          )}
          {errorMessage ? (
            <p className="text-danger mt-2 mb-0">{errorMessage}</p>
          ) : null}
        </td>
      ) : null}
    </tr>
  );
};

const AddPayments = ({ onUpdate, users }) => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;

  const [earnedMonth, setEarnedMonth] = useState(() => {
    // 1 month ago
    let month2 = month - 1;
    let year2 = year;
    if (month2 <= 0) {
      month2 = 12 + month2;
      year2 -= 1;
    }

    return `${year2}-${String(month2).padStart(2, "0")}`;
  });
  const [paidDate, setPaidDate] = useState("");

  const [amounts, setAmounts] = useState({});

  const [errorMessage, setErrorMessage] = useState();

  if (!users) {
    return <p>Loading users...</p>;
  }

  const usersWithNames = users.filter(user => user.name);

  const handleSubmit = async event => {
    event.preventDefault();
    setErrorMessage();

    const rows = Object.entries(amounts)
      .filter(([userID, amount]) => amount !== "")
      .map(([userID, amount]) => ({
        userID: parseInt(userID),
        amount: amountStringToInt(amount),
        earnedMonth,
        paidDate: emptyStringToNull(paidDate)
      }));

    if (rows.length > 0) {
      const result = await fetchWrapper("create_payments", {
        method: "POST",
        data: { rows: JSON.stringify(rows) }
      });

      if (result.success) {
        await onUpdate();
        setAmounts({});
      } else if (result.errorMessage) {
        setErrorMessage(result.errorMessage);
      } else {
        setErrorMessage("Unknown error");
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ maxWidth: 400 }}>
        <div className="form-row">
          <div className="form-group col-6">
            <label htmlFor="input_earned_month">Earned Month</label>
            <input
              type="text"
              pattern="\d\d\d\d-\d\d"
              required
              className="form-control"
              id="input_earned_month"
              value={earnedMonth}
              onChange={event => {
                setEarnedMonth(event.target.value);
              }}
            />
          </div>
          <div className="form-group col-6">
            <label htmlFor="input_paid_date">Paid Date</label>
            <input
              type="text"
              pattern="\d\d\d\d-\d\d-\d\d"
              className="form-control"
              id="input_paid_date"
              value={paidDate}
              onChange={event => {
                setPaidDate(event.target.value);
              }}
            />
          </div>
        </div>
        <table className="table">
          <tbody>
            {usersWithNames.map(user => (
              <tr key={user.id}>
                <td className="align-middle pl-0">{user.id}</td>
                <td className="align-middle">{user.name}</td>
                <td className="pr-0">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input
                      type="text"
                      pattern="-?\$?[\d,]+\.\d\d"
                      className="form-control"
                      value={amounts[user.id] ?? ""}
                      onChange={event => {
                        const newAmount = event.target.value;
                        setAmounts(obj => ({
                          ...obj,
                          [user.id]: newAmount
                        }));
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="submit" className="btn btn-primary">
          Add monthly payments
        </button>
      </form>
      {errorMessage ? <p className="text-danger">{errorMessage}</p> : null}
    </>
  );
};

const Payments = () => {
  useTitle("Payments");

  const user = useUser();

  const [users, setUsers] = useState();
  const [payments, setPayments] = useState();

  const fetchData = async () => {
    fetchWrapper("admin_data").then(result => {
      setUsers(result.users);
    });
    fetchWrapper("get_payments").then(result => {
      setPayments(result.payments);
    });
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (payments === undefined) {
    return null;
  }

  let multipleUsers = false;
  for (const payment of payments) {
    if (payment.user_id !== payments[0].user_id) {
      multipleUsers = true;
      break;
    }
  }

  const admin = user.role === "admin";

  let maxWidth = 400;
  if (admin) {
    maxWidth += 150;
  }
  if (multipleUsers) {
    maxWidth += 150;
  }

  return (
    <>
      <div className="table-responsive" style={{ maxWidth }}>
        <table className="table">
          <thead>
            <tr>
              <th title="Month earned">Earned</th>
              <th title="Date payment sent">Paid</th>
              {multipleUsers ? <th>User</th> : null}
              <th>Amount</th>
              {user.role === "admin" ? (
                <th style={{ width: 0 }}>Actions</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {payments.map(payment => (
              <Row
                key={payment.id}
                admin={admin}
                multipleUsers={multipleUsers}
                onUpdate={fetchData}
                payment={payment}
              />
            ))}
          </tbody>
        </table>
      </div>
      {admin ? (
        <>
          <h1>Add monthly payments</h1>
          <p>Leave paid date blank to enter a pending future payment.</p>
          <p>Entries will only be saved for users you add an amount for.</p>
          <AddPayments onUpdate={fetchData} users={users} />
        </>
      ) : null}
    </>
  );
};

export default Payments;
