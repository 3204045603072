import { useCallback, useLayoutEffect, useState } from "react";

// Based on https://gist.github.com/morajabi/523d7a642d8c0a2f71fcfa0d8b3d2846 and https://developer.mozilla.org/en-US/docs/Web/Events/resize

const getWidth = element => {
  if (!element) {
    return 0;
  }

  return element.getBoundingClientRect().width;
};

let running = false;
const resizeListener = () => {
  if (running) {
    return;
  }
  running = true;
  window.requestAnimationFrame(() => {
    window.dispatchEvent(new CustomEvent("optimizedResize"));
    running = false;
  });
};

export const useWidth = ref => {
  const [width, setWidth] = useState(getWidth(ref ? ref.current : null));

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return;
    }

    // Update client rect
    const newWidth = getWidth(ref.current);
    if (width !== newWidth) {
      setWidth(newWidth);
    }
  }, [ref, width]);

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    handleResize();

    window.addEventListener("resize", resizeListener);
    window.addEventListener("optimizedResize", handleResize);

    return () => {
      window.removeEventListener("resize", resizeListener);
      window.removeEventListener("optimizedResize", handleResize);
    };
  }, [handleResize, ref]);

  return width;
};

export default useWidth;
