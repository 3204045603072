import React, { useCallback, useEffect, useState } from "react";
import Protected from "./Protected";
import fetchWrapper from "./fetchWrapper";
import useTitle from "./useTitle";

const Row = ({ share, onUpdate }) => {
  const [editing, setEditing] = useState(false);
  const [controlledPercent, setControlledPercent] = useState(share.percent);
  const [controlledAdUnitFilter, setControlledAdUnitFilter] = useState(
    share.ad_unit_filter
  );
  const [errorMessage, setErrorMessage] = useState();

  const saveShare = useCallback(async () => {
    setErrorMessage();

    const result = await fetchWrapper("update_share", {
      method: "POST",
      data: {
        id: share.id,
        ad_unit_filter: controlledAdUnitFilter,
        percent: controlledPercent
      }
    });

    if (result.success) {
      await onUpdate();
      setEditing(false);
    } else if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    } else {
      setErrorMessage("Unknown error");
    }
  }, [controlledAdUnitFilter, controlledPercent, onUpdate, share.id]);

  const deleteShare = useCallback(async () => {
    setErrorMessage();

    const result = await fetchWrapper("delete_share", {
      method: "POST",
      data: {
        id: share.id
      }
    });

    if (result.success) {
      await onUpdate();
    } else if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    } else {
      setErrorMessage("Unknown error");
    }
  }, [onUpdate, share.id]);

  return (
    <tr>
      <td>{share.id}</td>
      <td>{share.site_name}</td>
      <td>{share.user_email}</td>
      <td>
        {!editing ? (
          `${share.percent}%`
        ) : (
          <div className="input-group input-group-sm" style={{ width: 75 }}>
            <input
              className="form-control"
              type="text"
              required
              value={controlledPercent}
              onChange={event => {
                setControlledPercent(event.target.value);
              }}
            />
            <div className="input-group-append">
              <span className="input-group-text">%</span>
            </div>
          </div>
        )}
      </td>
      <td>
        {!editing ? (
          share.ad_unit_filter
        ) : (
          <input
            className="form-control-sm"
            type="text"
            required
            value={controlledAdUnitFilter}
            onChange={event => {
              setControlledAdUnitFilter(event.target.value);
            }}
          />
        )}
      </td>
      <td>
        {editing ? (
          <div className="btn-group">
            <button className="btn btn-primary btn-sm" onClick={saveShare}>
              Save
            </button>
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => {
                setErrorMessage();
                setEditing(false);
                setControlledPercent(share.percent);
                setControlledAdUnitFilter(share.ad_unit_filter);
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="btn-group">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setErrorMessage();
                setEditing(true);
              }}
            >
              Edit
            </button>
            <button className="btn btn-secondary btn-sm" onClick={deleteShare}>
              Delete
            </button>
          </div>
        )}
        {errorMessage ? (
          <p className="text-danger mt-2 mb-0">{errorMessage}</p>
        ) : null}
      </td>
    </tr>
  );
};

const AddShare = ({ onUpdate, sites, users }) => {
  const [siteID, setSiteID] = useState(sites[0].id);
  const [userID, setUserID] = useState(users[0].id);
  const [percent, setPercent] = useState(10);
  const [adUnitFilter, setAdUnitFilter] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      setErrorMessage();

      const result = await fetchWrapper("create_share", {
        method: "POST",
        data: {
          site_id: siteID,
          user_id: userID,
          percent,
          ad_unit_filter: adUnitFilter
        }
      });

      if (result.success) {
        await onUpdate();

        setPercent(10);
      } else if (result.errorMessage) {
        setErrorMessage(result.errorMessage);
      } else {
        setErrorMessage("Unknown error");
      }
    },
    [adUnitFilter, onUpdate, percent, siteID, userID]
  );

  return (
    <>
      <form className="form-inline" onSubmit={handleSubmit}>
        <select
          className="form-control mr-sm-2 mb-2"
          value={siteID}
          onChange={event => {
            setSiteID(event.target.value);
          }}
        >
          {sites.map(site => (
            <option key={site.id} value={site.id}>
              {site.name}
            </option>
          ))}
        </select>
        <select
          className="form-control mr-sm-2 mb-2"
          value={userID}
          onChange={event => {
            setUserID(event.target.value);
          }}
        >
          {users.map(user => (
            <option key={user.id} value={user.id}>
              {user.email}
            </option>
          ))}
        </select>
        <div className="input-group mr-sm-2 mb-2" style={{ width: 100 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Percent"
            required
            value={percent}
            onChange={event => {
              setPercent(event.target.value);
            }}
          />
          <div className="input-group-append">
            <span className="input-group-text">%</span>
          </div>
        </div>
        <input
          type="text"
          className="form-control mr-sm-2 mb-2"
          placeholder="Ad Unit Filter"
          value={adUnitFilter}
          onChange={event => {
            setAdUnitFilter(event.target.value);
          }}
        />

        <button type="submit" className="btn btn-primary mb-2">
          Add share
        </button>
      </form>
      {errorMessage ? <p className="text-danger">{errorMessage}</p> : null}
    </>
  );
};

const Shares = () => {
  useTitle("Shares");

  const [shares, setShares] = useState();
  const [sites, setSites] = useState();
  const [users, setUsers] = useState();

  const fetchData = async () => {
    const result = await fetchWrapper("admin_data");

    setShares(result.shares);
    setSites(result.sites);
    setUsers(result.users);
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (shares === undefined || sites === undefined || users === undefined) {
    return null;
  }

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Site</th>
              <th>User Email</th>
              <th>Share</th>
              <th>Ad Unit Filter</th>
              <th style={{ width: 0 }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {shares.map(share => (
              <Row key={share.id} share={share} onUpdate={fetchData} />
            ))}
          </tbody>
        </table>
      </div>
      <h1>Add share</h1>
      <AddShare onUpdate={fetchData} sites={sites} users={users} />
    </>
  );
};

const ProtectedShares = () => {
  return (
    <Protected admin>
      <Shares />
    </Protected>
  );
};

export default ProtectedShares;
