import { Link } from "@reach/router";
import React, { useEffect, useState } from "react";
import Protected from "./Protected";
import fetchWrapper from "./fetchWrapper";
import useTitle from "./useTitle";

const Row = ({ email, id, name, role, shares, onUpdate }) => {
  const [editing, setEditing] = useState(false);
  const [controlledEmail, setControlledEmail] = useState(email);
  const [controlledName, setControlledName] = useState(name);
  const [controlledRole, setControlledRole] = useState(role);
  const [errorMessage, setErrorMessage] = useState();

  const saveUser = async event => {
    event.preventDefault();
    setErrorMessage();

    const result = await fetchWrapper("update_user", {
      method: "POST",
      data: {
        id,
        email: controlledEmail,
        name: controlledName,
        role: controlledRole
      }
    });

    if (result.success) {
      await onUpdate();
      setEditing(false);
    } else if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    } else {
      setErrorMessage("Unknown error");
    }
  };

  const deleteUser = async () => {
    setErrorMessage();

    const result = await fetchWrapper("delete_user", {
      method: "POST",
      data: {
        id
      }
    });

    if (result.success) {
      await onUpdate();
    } else if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    } else {
      setErrorMessage("Unknown error");
    }
  };

  return (
    <tr>
      <td>{id}</td>
      <td>
        {!editing ? (
          email
        ) : (
          <form onSubmit={saveUser}>
            <input
              className="form-control form-control-sm"
              type="email"
              required
              value={controlledEmail}
              onChange={event => {
                setControlledEmail(event.target.value);
              }}
            />
          </form>
        )}
      </td>
      <td>
        {!editing ? (
          name
        ) : (
          <form onSubmit={saveUser}>
            <input
              className="form-control form-control-sm"
              type="text"
              required
              value={controlledName}
              onChange={event => {
                setControlledName(event.target.value);
              }}
            />
          </form>
        )}
      </td>
      <td>
        {!editing ? (
          role
        ) : (
          <form onSubmit={saveUser}>
            <select
              className="form-control form-control-sm"
              value={controlledRole}
              onChange={event => {
                setControlledRole(event.target.value);
              }}
            >
              <option value="admin">admin</option>
              <option value="publisher">publisher</option>
            </select>
          </form>
        )}
      </td>
      <td>
        <ul className="list-unstyled mb-0">
          {shares.map((share, i) => (
            <li key={i}>{`${share.percent}% of ${share.site_name}`}</li>
          ))}
          {editing ? (
            <li>
              <Link to="/shares">Edit these on the Shares page</Link>
            </li>
          ) : null}
        </ul>
      </td>
      <td>
        {editing ? (
          <div className="btn-group">
            <button className="btn btn-primary btn-sm" onClick={saveUser}>
              Save
            </button>
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => {
                setErrorMessage();
                setEditing(false);
                setControlledEmail(email);
                setControlledRole(role);
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="btn-group">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setErrorMessage();
                setEditing(true);
              }}
            >
              Edit
            </button>
            <button className="btn btn-secondary btn-sm" onClick={deleteUser}>
              Delete
            </button>
          </div>
        )}
        {errorMessage ? (
          <p className="text-danger mt-2 mb-0">{errorMessage}</p>
        ) : null}
      </td>
    </tr>
  );
};

const AddUser = ({ onUpdate }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("publisher");
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async event => {
    event.preventDefault();
    setErrorMessage();

    const result = await fetchWrapper("create_user", {
      method: "POST",
      data: {
        email,
        password,
        role
      }
    });

    if (result.success) {
      await onUpdate();

      setEmail("");
      setPassword("");
      setRole("publisher");
    } else if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    } else {
      setErrorMessage("Unknown error");
    }
  };

  return (
    <>
      <form className="form-inline" onSubmit={handleSubmit}>
        <input
          type="email"
          className="form-control mr-sm-2 mb-2"
          placeholder="Email"
          required
          value={email}
          onChange={event => {
            setEmail(event.target.value);
          }}
        />
        <input
          autoComplete="new-password"
          type="password"
          className="form-control mr-sm-2 mb-2"
          placeholder="Password"
          required
          value={password}
          onChange={event => {
            setPassword(event.target.value);
          }}
        />
        <select
          className="form-control mr-sm-2 mb-2"
          value={role}
          onChange={event => {
            setRole(event.target.value);
          }}
        >
          <option value="admin">admin</option>
          <option value="publisher">publisher</option>
        </select>
        <button type="submit" className="btn btn-primary mb-2">
          Add user
        </button>
      </form>
      {errorMessage ? <p className="text-danger">{errorMessage}</p> : null}
    </>
  );
};

const Users = () => {
  useTitle("Users");

  const [users, setUsers] = useState();
  const [shares, setShares] = useState();

  const fetchData = async () => {
    const result = await fetchWrapper("admin_data");

    setUsers(result.users);
    setShares(result.shares);
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (users === undefined || shares === undefined) {
    return null;
  }

  for (const user of users) {
    user.shares = shares.filter(share => share.user_id === user.id);
  }

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Name</th>
              <th>Role</th>
              <th>Shares</th>
              <th style={{ width: 0 }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map(({ email, id, name, role, shares }) => (
              <Row
                key={id}
                email={email}
                name={name}
                id={id}
                role={role}
                shares={shares}
                onUpdate={fetchData}
              />
            ))}
          </tbody>
        </table>
      </div>
      <h1>Add user</h1>
      <p>
        This will <span className="text-warning">not</span> email new users, so
        it's your responsibility to tell them about their account.
      </p>
      <AddUser onUpdate={fetchData} />
    </>
  );
};

const ProtectedUsers = () => {
  return (
    <Protected admin>
      <Users />
    </Protected>
  );
};

export default ProtectedUsers;
