import React from "react";
import Login from "./Login";
import useUser from "./useUser";

const Protected = ({ admin, children }) => {
  const user = useUser();

  if (user.email === undefined) {
    return <Login />;
  }

  if (admin && user.role !== "admin") {
    return <div>Permission denied</div>;
  }

  return children;
};

export default Protected;
