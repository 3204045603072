import orderBy from "lodash/orderBy";
import React, { useCallback, useMemo, useState } from "react";
import * as format from "./format";
import "./DataTable.css";

const formatVal = (col, val) => {
  switch (col) {
    case "Impressions":
    case "Pageviews":
      return format.integer(val);
    case "Revenue (USD)":
    case "CPM (USD)":
    case "pvRPM (USD)":
      return format.currency(val);
    case "Imp:PV":
      return val.toFixed(3);
    default:
      return val;
  }
};

const SortArrow = ({ direction }) => {
  const arrowClass = direction ? "sort-selected" : "sort-unselected";

  return (
    <svg className="ml-2 sort" viewBox="0 0 90 150">
      {direction !== "asc" ? (
        <polygon className={arrowClass} points="0,80 90,80 45,150" />
      ) : null}
      {direction !== "desc" ? (
        <polygon className={arrowClass} points="0,70 90,70 45,0" />
      ) : null}
    </svg>
  );
};

const ColumnHeader = ({
  filterOptions,
  filterValue = "all",
  i,
  name,
  onColClick,
  onFilterChange,
  sortCols
}) => {
  let direction;
  for (const sortCol of sortCols) {
    if (sortCol[0] === i) {
      direction = sortCol[1];
      break;
    }
  }

  return (
    <th
      className="th-sort"
      onClick={event => {
        onColClick(i, event);
      }}
    >
      {name}
      <SortArrow direction={direction} />
      {filterOptions && filterOptions.length > 1 ? (
        <select
          className="form-control mt-1"
          value={filterValue}
          onChange={event => {
            onFilterChange(i, event.target.value);
          }}
          onClick={event => {
            event.stopPropagation();
          }}
          style={{
            width: "inherit"
          }}
        >
          <option value="all">Show All</option>
          {filterOptions.map(val => (
            <option key={val} value={val}>
              {val}
            </option>
          ))}
        </select>
      ) : null}
    </th>
  );
};

// Would be nice to make this more generic
const Footer = React.memo(({ cols, rows }) => {
  if (cols.length <= 3 || rows.length <= 1) {
    return null;
  }

  let impressions = 0;
  let pageviews = 0;
  let revenue = 0;
  for (const row of rows) {
    impressions += row[row.length - 6];
    pageviews += row[row.length - 5];
    revenue += row[row.length - 3];
  }

  return (
    <tfoot>
      <tr>
        <th colSpan={cols.length - 6}>Totals</th>
        <th>{format.integer(impressions)}</th>
        <th>{format.integer(pageviews)}</th>
        <th>{(pageviews > 0 ? impressions / pageviews : 0).toFixed(3)}</th>
        <th>{format.currency(revenue)}</th>
        <th>
          {format.currency(
            impressions > 0 ? (revenue * 1000) / impressions : 0
          )}
        </th>
        <th>
          {format.currency(pageviews > 0 ? (revenue * 1000) / pageviews : 0)}
        </th>
      </tr>
    </tfoot>
  );
});

const noFilter = [
  "Date",
  "Impressions",
  "Pageviews",
  "Imp:PV",
  "Revenue (USD)",
  "CPM (USD)",
  "pvRPM (USD)"
];

const DataTable = React.memo(({ cols, rows }) => {
  const defaultSortCol = [0, noFilter.includes(cols[0]) ? "desc" : "asc"];

  const [filterValues, setFilterValues] = useState(Array(cols.length));
  const [sortCols, setSortCols] = useState([defaultSortCol]);
  const [prevCols, setPrevCols] = useState(cols);

  // If the columns changed, reset filtering like https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
  if (cols !== prevCols) {
    // See if existing filter references columns that are still in cols, and if so keep them
    const newFilterValues = cols.map((col, i) => {
      const prevColsInd = prevCols.indexOf(col);
      if (prevColsInd < 0) {
        // This column is new, so initialize filter to undefined
        return undefined;
      }

      // Column was shown previously, so copy over filter
      return filterValues[prevColsInd];
    });
    setFilterValues(newFilterValues);

    // See if existing sort references columns that are still in cols, and if so keep them
    const newSortCols = [];
    for (const sortCol of sortCols) {
      const prevCol = prevCols[sortCol[0]];
      if (prevCol) {
        const colsInd = cols.indexOf(prevCol);
        if (colsInd >= 0) {
          newSortCols.push([colsInd, sortCol[1]]);
        }
      }
    }
    if (newSortCols.length === 0) {
      newSortCols.push(defaultSortCol);
    }
    setSortCols(newSortCols);

    setPrevCols(cols);
  }

  // Either a set (filterable) or undefined (not filterable)
  const filterOptions = useMemo(() => {
    const filterOptions = cols.map((col, i) => {
      if (noFilter.includes(col)) {
        return undefined;
      }

      return new Set();
    });
    for (const row of rows) {
      for (let i = 0; i < filterOptions.length; i++) {
        if (filterOptions[i]) {
          filterOptions[i].add(row[i]);
        }
      }
    }
    for (let i = 0; i < filterOptions.length; i++) {
      if (filterOptions[i]) {
        filterOptions[i] = Array.from(filterOptions[i]).sort();
      }
    }

    return filterOptions;
  }, [cols, rows]);

  const onColClick = useCallback(
    (i, event) => {
      const col = cols[i];

      let found = false;
      let clonedSortCols = JSON.parse(JSON.stringify(sortCols));

      let sortSequence = ["asc", "desc"];
      if (noFilter.includes(col)) {
        sortSequence = ["desc", "asc"];
      }

      const nextOrder = (col2, sortCol) => {
        // Move up to next entry in sortSequence
        let j = sortSequence.indexOf(sortCol[1]) + 1;
        if (j >= sortSequence.length) {
          j = 0;
        }
        return sortSequence[j];
      };

      // If this column is already in sortBys and shift is pressed, update
      if (event.shiftKey) {
        for (const sortCol of clonedSortCols) {
          if (sortCol[0] === i) {
            sortCol[1] = nextOrder(col, sortCol);
            found = true;
            break;
          }
        }

        // If this column is not in sortBys and shift is pressed, append
        if (!found) {
          clonedSortCols.push([i, sortSequence[0]]);
          found = true;
        }
      }

      // If this column is the only one in sortBys, update order
      if (!found && clonedSortCols.length === 1 && clonedSortCols[0][0] === i) {
        clonedSortCols[0][1] = nextOrder(col, clonedSortCols[0]);
        found = true;
      }

      // Otherwise, reset to sorting only by this column, default order
      if (!found) {
        clonedSortCols = [[i, sortSequence[0]]];
      }

      setSortCols(clonedSortCols);
    },
    [cols, sortCols]
  );

  const onFilterChange = useCallback(
    (i, value) => {
      const newFilterValues = [...filterValues];
      newFilterValues[i] = value;
      setFilterValues(newFilterValues);
    },
    [filterValues]
  );

  const rowsFiltered = useMemo(
    () =>
      rows.filter(row => {
        let allGood = true;
        for (let i = 0; i < filterValues.length; i++) {
          const filterValue = filterValues[i];
          if (
            filterValue !== undefined &&
            filterValue !== "all" &&
            row[i] !== filterValue
          ) {
            allGood = false;
            break;
          }
        }
        return allGood;
      }),
    [filterValues, rows]
  );

  const rowsFilteredSorted = useMemo(
    () =>
      orderBy(
        rowsFiltered,
        sortCols.map(sortCol => row => {
          let i = sortCol[0];
          if (typeof i !== "number" || i >= row.length || i >= cols.length) {
            i = 0;
          }

          return row[i];
        }),
        sortCols.map(sortBy => sortBy[1])
      ),
    [cols.length, rowsFiltered, sortCols]
  );

  return (
    <div className="table-responsive">
      <table className="table table-hover table-striped table-sm mt-3">
        <thead>
          <tr>
            {cols.map((col, i) => (
              <ColumnHeader
                key={col}
                i={i}
                name={col}
                filterOptions={filterOptions[i]}
                filterValue={filterValues[i]}
                onColClick={onColClick}
                onFilterChange={onFilterChange}
                sortCols={sortCols}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {rowsFilteredSorted.map((row, i) => (
            <tr key={i}>
              {row.map((val, i) => (
                <td key={i}>{formatVal(cols[i], val)}</td>
              ))}
            </tr>
          ))}
        </tbody>
        <Footer cols={cols} rows={rowsFilteredSorted} />
      </table>
    </div>
  );
});

export default DataTable;
