import React, { useCallback, useState } from "react";
import fetchWrapper from "./fetchWrapper";
import useTitle from "./useTitle";
import useUser from "./useUser";

const wrapperStyle = { maxWidth: 400 };

const Account = () => {
  useTitle("Account");

  const user = useUser();

  const [email, setEmail] = useState(user.email);
  const [emailSuccess, setEmailSuccess] = useState();
  const [emailError, setEmailError] = useState();
  const [submittingEmail, setSubmittingEmail] = useState(false);

  const [passwordOld, setPasswordOld] = useState("");
  const [passwordNew1, setPasswordNew1] = useState("");
  const [passwordNew2, setPasswordNew2] = useState("");
  const [passwordSuccess, setPasswordSuccess] = useState();
  const [passwordError, setPasswordError] = useState();
  const [submittingPassword, setSubmittingPassword] = useState(false);

  const submitEmail = useCallback(
    async event => {
      event.preventDefault();
      setEmailSuccess();
      setEmailError();
      setSubmittingEmail(true);

      const result = await fetchWrapper("update_email", {
        method: "POST",
        data: {
          email
        }
      });

      setSubmittingEmail(false);
      if (result.success) {
        setEmailSuccess("Email address successfully changed");
        user.actions.login({
          email,
          role: user.role
        });
      } else if (result.errorMessage) {
        setEmailError(result.errorMessage);
      } else {
        setEmailError("Unknown error");
      }
    },
    [email, user.actions, user.role]
  );

  const submitPassword = useCallback(
    async event => {
      event.preventDefault();
      setPasswordSuccess();
      setPasswordError();
      setSubmittingPassword(true);

      const result = await fetchWrapper("update_password", {
        method: "POST",
        data: {
          passwordOld,
          passwordNew1,
          passwordNew2
        }
      });

      setSubmittingPassword(false);
      if (result.success) {
        setPasswordSuccess("Password successfully changed");
      } else if (result.errorMessage) {
        setPasswordError(result.errorMessage);
      } else {
        setPasswordError("Unknown error");
      }
    },
    [passwordOld, passwordNew1, passwordNew2]
  );

  return (
    <div style={wrapperStyle}>
      <h1>Change email address</h1>
      <form onSubmit={submitEmail}>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            required
            value={email}
            onChange={event => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={submittingEmail}
        >
          Change email address
        </button>
      </form>
      {emailSuccess ? (
        <p className="text-success mt-3">{emailSuccess}</p>
      ) : null}
      {emailError ? <p className="text-danger mt-3">{emailError}</p> : null}

      <h1 className="mt-5">Change password</h1>
      <form onSubmit={submitPassword}>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Current password</label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            required
            value={passwordOld}
            onChange={event => {
              setPasswordOld(event.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword2">New password</label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword2"
            required
            value={passwordNew1}
            onChange={event => {
              setPasswordNew1(event.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword3">Re-type new password</label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword3"
            required
            value={passwordNew2}
            onChange={event => {
              setPasswordNew2(event.target.value);
            }}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={submittingPassword}
        >
          Change password
        </button>
      </form>
      {passwordSuccess ? (
        <p className="text-success mt-3">{passwordSuccess}</p>
      ) : null}
      {passwordError ? (
        <p className="text-danger mt-3">{passwordError}</p>
      ) : null}
    </div>
  );
};

export default Account;
