import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "./DataTable";
import MyDateRangePicker from "./MyDateRangePicker";
import downloadFile from "./downloadFile";
import fetchWrapper from "./fetchWrapper";
import useTitle from "./useTitle";
import useUser from "./useUser";
import "react-dates/lib/css/_datepicker.css";
import "./Reporting.css";

const capitalize = str =>
  str === "adUnit"
    ? "Ad Unit"
    : `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const ActionButtons = ({ disabled, run }) => {
  const [exportingCSV, setExportingCSV] = useState(false);

  const exportCSV = async event => {
    event.preventDefault();

    setExportingCSV(true);

    const { cols, rows } = await run();
    const data = `${cols.join(",")}\n${rows
      .map(row => row.join(","))
      .join("\n")}`;
    downloadFile(`ad-report-${new Date().toISOString()}.csv`, data);

    setExportingCSV(false);
  };

  return (
    <>
      <button
        className="btn btn-primary mr-3"
        disabled={disabled}
        type="submit"
      >
        {disabled && !exportingCSV ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Loading...
          </>
        ) : (
          "View Data"
        )}
      </button>
      <button
        className="btn btn-primary"
        disabled={disabled}
        onClick={exportCSV}
        type="button"
      >
        {disabled && exportingCSV ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Loading...
          </>
        ) : (
          "Export CSV"
        )}
      </button>
    </>
  );
};

const Reporting = () => {
  useTitle("Reporting");

  const user = useUser();

  const params = new URLSearchParams(window.location.hash.slice(1));

  const [endDate, setEndDate] = useState(
    moment(params.get("endDate") || new Date()).subtract(1, "day")
  );
  const [startDate, setStartDate] = useState(
    params.get("startDate")
      ? moment(params.get("startDate"))
      : endDate
      ? endDate.clone().subtract(1, "month")
      : new Date()
  );
  const [timeGrouping, setTimeGrouping] = useState(
    params.get("timeGrouping") || "day"
  );
  const [userOrTotal, setUserOrTotal] = useState(
    user.role === "admin" ? params.get("userOrTotal") || "total" : "user"
  );
  const [running, setRunning] = useState(false);
  const [data, setData] = useState({
    cols: [],
    recordsLastUpdated: undefined,
    rows: []
  });

  const [dimensions, setDimensions] = useState({
    date: params.get("date") === "false" ? false : true,
    site: params.get("site") === "false" ? false : true,
    adUnit: params.get("adUnit") === "true" ? true : false,
    bidder: params.get("bidder") === "true" ? true : false,
    device: params.get("device") === "true" ? true : false
  });
  const setDimension = (dimension, checked) => {
    setDimensions({
      ...dimensions,
      [dimension]: checked
    });
  };

  const run = useCallback(async () => {
    setRunning(true);

    const query = {
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      timeGrouping,
      userOrTotal,
      ...dimensions
    };

    const result = await fetchWrapper("get_records", {
      method: "POST",
      data: query
    });

    const cols = [
      ...Object.entries(dimensions)
        .filter(([key, value]) => value)
        .map(([key]) => capitalize(key)),
      "Impressions",
      "Pageviews",
      "Imp:PV",
      "Revenue (USD)",
      "CPM (USD)",
      "pvRPM (USD)"
    ];

    const rows = result.records
      ? result.records.map(record => {
          return [
            ...Object.entries(dimensions)
              .filter(([key, value]) => value)
              .map(([key]) => record[key]),
            record.impressions,
            record.pageviews,
            record.ipv,
            record.revenue,
            record.cpm,
            record.rpm
          ];
        })
      : [];

    setRunning(false);

    return { cols, recordsLastUpdated: result.recordsLastUpdated, rows };
  }, [startDate, endDate, dimensions, timeGrouping, userOrTotal]);

  // On initial load, display default data
  useEffect(() => {
    run().then(({ cols, recordsLastUpdated, rows }) => {
      setData({ cols, recordsLastUpdated, rows });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let recordsLastUpdated = "???";
  if (data.recordsLastUpdated) {
    const date = moment.utc(data.recordsLastUpdated);
    recordsLastUpdated = date.local().format("YYYY-MM-DD h:mm a");
  }

  return (
    <>
      <form
        onSubmit={async event => {
          event.preventDefault();

          const formData = {
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            timeGrouping,
            userOrTotal,
            ...dimensions
          };
          const params = new URLSearchParams(formData).toString();
          window.history.replaceState(null, null, `#${params}`);

          const { cols, recordsLastUpdated, rows } = await run();
          setData({ cols, recordsLastUpdated, rows });
        }}
      >
        <div className="float-lg-left mb-3 responsive-form-wrapper">
          <h6 style={{ paddingBottom: 2 }}>Date Range</h6>
          <MyDateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>

        <div className="float-lg-left mb-3 responsive-form-wrapper">
          <h6>Dimensions</h6>
          <div className="form-inline">
            {Object.entries(dimensions).map(([dimension, value], i) => {
              const id = `dimensionCheckbox-${dimension}`;

              return (
                <div
                  className={
                    i >= Object.keys(dimensions).length - 1
                      ? "form-check"
                      : "form-check mr-4"
                  }
                  key={dimension}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={id}
                    checked={value}
                    onChange={event => {
                      setDimension(dimension, event.target.checked);
                    }}
                  />
                  <label className="form-check-label" htmlFor={id}>
                    {dimension === "date" ? (
                      <select
                        className="form-control"
                        value={timeGrouping}
                        onChange={event => {
                          setTimeGrouping(event.target.value);
                        }}
                      >
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                      </select>
                    ) : (
                      capitalize(dimension)
                    )}
                  </label>
                </div>
              );
            })}
          </div>
        </div>

        {user.role === "admin" ? (
          <div className="float-lg-left mb-3">
            <h6>Admin Options</h6>
            <div className="form-inline">
              <select
                className="form-control"
                value={userOrTotal}
                onChange={event => {
                  setUserOrTotal(event.target.value);
                }}
              >
                <option value="user">User Cut</option>
                <option value="publisher">Publisher Cut</option>
                <option value="total">Total</option>
              </select>
            </div>
          </div>
        ) : null}

        <div className="clearfix" />

        <div className="w-100">
          <div className="float-md-left mr-3 mb-3">
            <ActionButtons disabled={running} run={run} setData={setData} />
          </div>
          <div className="float-md-right text-md-right mb-3">
            Warning: This data will not exactly match what you are paid. These
            are just estimates.
            <br />
            Data last updated: {recordsLastUpdated}
          </div>
        </div>
      </form>

      <DataTable cols={data.cols} rows={data.rows} />
    </>
  );
};

export default Reporting;
