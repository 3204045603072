import { Link } from "@reach/router";
import React, { useCallback, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Protected from "./Protected";
import fetchWrapper from "./fetchWrapper";
import useUser from "./useUser";
import useTitle from "./useTitle";

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? "nav-link active" : "nav-link"
      };
    }}
  />
);

const UserWrapper = ({ children }) => {
  const user = useUser();
  const title = useTitle();
  const [expanded, setExpanded] = useState(false);

  const logout = useCallback(async () => {
    if (user.email || user.role) {
      await fetchWrapper("logout", {
        method: "POST"
      });

      user.actions.logout();
    }
  }, [user.actions, user.email, user.role]);

  const toggleExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const collapse = useCallback(() => {
    setExpanded(false);
  }, []);

  return (
    <Protected>
      <Navbar
        bg="light"
        collapseOnSelect
        className="mb-3"
        expand="sm"
        expanded={expanded}
        onToggle={toggleExpanded}
      >
        <Navbar.Brand className="d-sm-none">{title}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="navbar-nav mr-auto">
            <NavLink onClick={collapse} to="/">
              Dashboard
            </NavLink>
            <NavLink onClick={collapse} to="/reporting">
              Reporting
            </NavLink>
            <NavLink onClick={collapse} to="/payments">
              Payments
            </NavLink>
            {user.role === "admin" ? (
              <NavLink onClick={collapse} to="users">
                Users
              </NavLink>
            ) : null}
            {user.role === "admin" ? (
              <NavLink onClick={collapse} to="sites">
                Sites
              </NavLink>
            ) : null}
            {user.role === "admin" ? (
              <NavLink onClick={collapse} to="shares">
                Shares
              </NavLink>
            ) : null}
            {user.role === "admin" ? (
              <NavLink onClick={collapse} to="freestar">
                Freestar
              </NavLink>
            ) : null}
          </div>
          <div className="navbar-nav ml-auto">
            <NavLink onClick={collapse} to="account">
              Account
            </NavLink>
            <button
              className="btn btn-link nav-link border-0 text-left"
              onClick={logout}
            >
              Logout
            </button>
          </div>
        </Navbar.Collapse>
      </Navbar>
      <div className="container-fluid">{children}</div>
    </Protected>
  );
};

export default UserWrapper;
