import { useEffect } from "react";
import create from "zustand";

const [useTitleStore] = create(set => ({
  page: "Publisher Dashboard",

  actions: {
    setPage: page => {
      document.title = `${page} - Publisher Dashboard`;

      set(() => ({ page }));
    }
  }
}));

const useTitle = subPage => {
  const titleStore = useTitleStore();

  useEffect(() => {
    if (subPage !== undefined) {
      titleStore.actions.setPage(subPage);
    }
  }, [subPage, titleStore.actions]);

  return titleStore.page;
};

export default useTitle;
