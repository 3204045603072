import React, { useCallback, useState } from "react";
import fetchWrapper from "./fetchWrapper";
import useTitle from "./useTitle";
import "./ResetPassword.css";

const ResetPasswordRequest = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();

      await fetchWrapper("lost_password", {
        method: "POST",
        data: {
          email
        }
      });

      setSubmitted(true);
    },
    [email]
  );

  useTitle("Reset Password");

  return (
    <div className="centered-wrapper">
      <div className="reset-password-container">
        <h1>Reset Password</h1>
        {submitted ? (
          <p>Check your email for further instructions.</p>
        ) : (
          <>
            <p>
              Enter your account's email address below and you will be sent
              instructions on how to reset your password.
            </p>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                className="form-control"
                placeholder="Email address"
                required
                value={email}
                onChange={event => {
                  setEmail(event.target.value);
                }}
              />
              <button
                type="submit"
                className="btn btn-lg btn-primary btn-block"
              >
                Reset Password
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordRequest;
