import { Router } from "@reach/router";
import React, { useEffect, useState } from "react";
import Account from "./Account";
import Dashboard from "./Dashboard";
import Freestar from "./Freestar";
import Payments from "./Payments";
import Reporting from "./Reporting";
import ResetPassword from "./ResetPassword";
import ResetPasswordRequest from "./ResetPasswordRequest";
import Shares from "./Shares";
import Sites from "./Sites";
import UserWrapper from "./UserWrapper";
import Users from "./Users";
import fetchWrapper from "./fetchWrapper";
import useUser from "./useUser";

const App = () => {
  const [initialLoad, setInitialLoad] = useState(true);
  const user = useUser();

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchWrapper("user_info");

      if (result.email !== undefined && result.role !== undefined) {
        user.actions.login({
          email: result.email,
          role: result.role
        });
      }

      setInitialLoad(false);
    };

    try {
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (initialLoad) {
    return null;
  }

  return (
    <Router>
      <UserWrapper path="/">
        <Dashboard path="/" />
        <Reporting path="/reporting" />
        <Payments path="/payments" />
        <Account path="/account" />
        <Users path="/users" />
        <Sites path="/sites" />
        <Shares path="/shares" />
        <Freestar path="/freestar" />
      </UserWrapper>
      <ResetPasswordRequest path="/reset-password" />
      <ResetPassword path="/reset-password/:token" />
    </Router>
  );
};

export default App;
